<template>
  <div style="margin-top: 16px;">
    <div class="card">
      <span>识别状态：</span>
      <el-select v-model="readValue"
                 style="margin-right:24px"
                 @change="readChange"
                 placeholder="请选择">

        <el-option v-for="item in options"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
        </el-option>
      </el-select>
      <span>审核状态：</span>
      <el-select v-model="checkValue"
                 @change="checkChange"
                 placeholder="请选择">

        <el-option v-for="item in checkoptions"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
        </el-option>
      </el-select>

      <div style="float:right">
        <el-button type="primary"
                   @click="search"
                   icon="el-icon-search">查询</el-button>
        <el-button type=""
                   @click="reset">
          <img style="width:14px;height:14px"
               src="@/assets/reset.png">
          重置
        </el-button>
      </div>
    </div>
    <div class='card'>
      <div class='btn'>
        <el-button type='primary'
                   @click='check'>批量审核</el-button>
        <el-button type=''
                   @click='add'>上传答题卡</el-button>
        <!--
        <el-button type='primary'
                   @click='uploadFile'>压缩包上传</el-button>

        <span class="tips">tips:上传的压缩包里只能是图片,并且压缩包格式仅支持zip和rar格式</span> -->
        <el-progress v-if="uploadProgressDate"
                     :percentage="uploadProgressDate" />
        <input type="file"
               v-show="false"
               id="myInput" />
        <div style="margin-left:16px;color: var(--neutral-color-666666, #666);
font-size: 14px;">已选择<span style="color:#3F9EFF">{{this.multipleSelection.length}}</span>项</div>
      </div>

      <!-- <Table ref="TablesRef"
           :tableData="tableData" >


           </Table> -->
      <div class="bg-content"
           v-loading="uploading"
           :element-loading-text="loadingText"
           style="

    float: right;
    width: 141px;
    top: -46px;
"></div>
      <el-table border
                highlight-current-row
                ref="multipleTable"
                @selection-change="handleSelectionChange"
                :stripe="true"
                :data="tableData">
        <el-table-column type="selection"
                         width="45">
        </el-table-column>
        <el-table-column prop="edu_paper_answer_sheet_id"
                         align="left"
                         label="考试学生答题ID" />
        <el-table-column prop="create_time"
                         align="left"
                         label="上传时间" />
        <el-table-column prop="identify_status"
                         align="left"
                         label="识别状态">
          <template slot-scope="scope">
            <div :class="{'ypg':scope.row.identify_status =='SUCCESS','unload':scope.row.identify_status !='SUCCESS'}">
              {{scope.row.identify_status  =='SUCCESS' ? "识别完成":scope.row.identify_status =='PROCESSING' ?'处理中':'识别失败'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="url"
                         align="left"
                         label="答题卡图片">
          <template slot-scope="scope">
            <div style="display:flex">
              <el-image style="width: 50px; height: 50px"
                        v-for="(item,index) in scope.row.urllist"
                        :key="index"
                        :src="item"
                        :preview-src-list="Object.values(scope.row.urllist)"></el-image>
              <!-- <el-image style="width: 50px; height: 50px"
                      :src="scope.row.url2"
                      v-show="scope.row.url2"
                      :preview-src-list="[scope.row.url,scope.row.url2]"></el-image> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="choice_res"
                         align="left"
                         label="选择题识别结果">
          <template slot-scope="scope">
            {{scope.row.choice_res && scope.row.choice_res.match(/[a-zA-Z]/g) ? scope.row.choice_res.match(/[a-zA-Z]/g).join(""):'-'}}

          </template>
        </el-table-column>
        <el-table-column prop="status"
                         align="left"
                         label="审核状态">
          <template slot-scope="scope">
            <div :class="{'ypg':scope.row.status !=0,'unload':scope.row.status == 0}">
              {{scope.row.status  ==0 ? "未审核":"已审核"}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         align="left"
                         fixed="right"
                         label="操作">
          <template slot-scope="{row}">

            <div class="text_btn_primary"
                 @click="toInfo(row)">审核</div>
            <div class="text_btn_danger"
                 @click="toDel(row)">删除</div>

          </template>

        </el-table-column>
      </el-table>
      <pagination ref="pagination"
                  :get-data="initData"
                  :now-page.sync="page"
                  :now-size.sync="size"
                  :total="totalElements" />
    </div>
    <uploadDialog ref="dialogRef"
                  :showys="true"
                  :showysbar="true" />

  </div>
</template>

<script>
import { getSheetList, uploadSheetSchedule } from '@/api/sheetHn.js'
import { uploadSheetFile } from '@/api/uploadHn.js'
import { loadings } from '@/utils/http.js'
import { debounce } from 'lodash';
import { detBatchTaskid, taskStatus } from '@/api/sheetHn.js'


import uploadDialog from './uploadDialog.vue'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      pageName: 'sheetList',
      loadings,
      uploading: false,
      loadingText: '',
      tableColumns: [
        { prop: 'test_sheet_id', align: 'left', label: '考试学生答题ID', },
        // { prop: 'url', align: 'left', label: '图片1路径' },
        // { prop: 'url2', align: 'left', label: '图片2路径' },
        { prop: 'create_time', align: 'left', label: '上传时间', },
        { prop: 'status', align: 'left', label: '审核状态', isStatus: true },
        {
          prop: 'd', align: 'left', label: '操作', width: '200', type: 'text', btns: [
            { name: '', class: 'text_btn_primary', event: 'toInfo', isChangeText: true },
          ]
        },
      ],
      startLoading: 0,
      multipleSelection: [],
      options: [{
        value: '0',
        label: '识别失败'
      }, {
        value: '1',
        label: '识别完成'
      }],
      checkoptions: [{
        value: '0',
        label: '未审核'
      }, {
        value: '1',
        label: '已审核'
      }],
      selectedFile: null,
      uploadProgressDate: 0,
      readValue: null,
      checkValue: null,
      successFlag: false,
      sitv: undefined//是否加载中定时器
    }
  },
  components: { uploadDialog },
  created () {
  },
  watch: {
    // loadings: {
    //   handler () {
    //     console.log("loading")
    //     if (loadings.visible) {
    //       console.log("loading")
    //       // loadings.close()
    //       loadings.visible = false
    //     }
    //   },
    // deep: true
    // }
  },
  mounted () {
    this.$bus.$on('refreshList', () => {
      console.log('执行力')
      // 在这里执行刷新列表的操作
      this.refresh();
    });
    // document.getElementsByClassName('view_warp')[0].style.background = 'none'
    // this.setTablesColums()
  },
  beforeRouteEnter (to, from, next) {
    next();
    // document.getElementsByClassName('view_warp')[0].style.background = 'none'
  },
  methods: {
       toDel (row) {
      console.log(row)
      if (row.status == 1) {
        this.$notify({
          title: '提示',
          message: '已审核的答题卡不能删除!',
          type: 'warning'
        });
        return
      }
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/henan/del_answer',
          method: 'post',
          data: {
            edu_paper_answer_sheet_id: row.edu_paper_answer_sheet_id
          }
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.search()
        })
      })

    },
    async check () {
      let arr = []
      this.multipleSelection.map((item) => {
        arr.push(item.edu_paper_answer_sheet_id)
      })
      arr = arr.join(",");
      let params = {
        // page: this.page, limit: this.size,
        self: 1,
        edu_paper_id: this.$route.query.paper_id || window.localStorage.getItem("edu_paper_id"),
        edu_paper_answer_sheet_id: arr,
      }
      if (arr.length > 0) {


        const { data } = await getSheetList(params)
        console.log('datassssssssss', data.list[0])
        let cardUrlObj = data.list[0].urllist
        window.localStorage.setItem("edu_paper_id", this.$route.query.paper_id || window.localStorage.getItem('edu_paper_id'))
        window.localStorage.setItem("cardUrlObj", JSON.stringify(cardUrlObj))
        // this.$router.push('/exaManage/reviewSheet?sheet_id=' + row.test_sheet_id + "&paper_id=" + this.$route.query.id + '&status=' + row.status)


        this.$router.push({
          path: '/makeSheet/allReviewSheet',
          query: {
            data: JSON.stringify(data),
            sheet_id: data.list[0].edu_paper_answer_sheet_id,
            paper_id: this.$route.query.paper_id,
            status: data.list[0].status
          }
        });
      }
    },
    readChange (val) {
      console.log('val', val)
    },
    checkChange (val) {
      console.log('val', val)
    },
    reset () {
      this.readValue = ''
      this.checkValue = ''
      this.refresh();
    },
    handleSelectionChange (val) {

      this.multipleSelection = val;
      console.log('this.multipleSelection', this.multipleSelection)
    },
    getClass (prop, value) {
      return value ? 'pass' : 'notpass'
    },
    // handleFileSelect (event) {
    //   this.selectedFile = event.target.files[0];
    // },

    // async uploadFile (event) {
    //   this.uploadProgressDate = 0
    //   try {
    //     // 获取 input[type="file"] 元素
    //     const input = document.getElementById('myInput')
    //     if (!input) {
    //       throw new Error('找不到 input[type="file"] 元素')
    //     }

    //     // 移除之前的事件监听器
    //     // input.removeEventListener('change', this.handleFileSelect)

    //     // 监听 input[type="file"] 的 change 事件
    //     const fileSelected = new Promise((resolve) => {
    //       input.addEventListener('change', (event) => {
    //         const file = event.target.files[0] // 获取所选文件
    //         if (!file) {
    //           return // 如果没有选择文件，则不执行后续操作
    //         }

    //         if (file.name.includes('zip') || file.name.includes('rar')) {
    //           // 处理所选文件
    //           this.handleFileSelect(event)
    //           resolve()

    //         } else {
    //           this.$message({
    //             message: '只能上传 zip 或 rar 文件',
    //             type: 'error'
    //           })
    //           input.value = '' // 清空已选择的文件
    //           return

    //         }
    //       })
    //     })

    //     // 触发 input[type="file"] 的 click 事件
    //     input.click()

    //     // 等待用户选择文件
    //     await fileSelected

    //     // 每个文件切片大小定为45MB
    //     const sliceSize = 45 * 1024 * 1024

    //     //文件大小限制为最大1个G，可根据需求修改
    //     const fileSizeLimit = 1 * 1024 * 1024 * 1024

    //     const blob = this.selectedFile
    //     const fileSize = blob.size// 文件大小
    //     const fileName = blob.name// 文件名
    //     //计算文件切片总数
    //     const totalSlice = Math.ceil(fileSize / sliceSize)

    //     if (fileSize <= fileSizeLimit) {


    //       let success = false
    //       let uploadedBytes = 0;
    //       let uploadedBytesTotal = 0;

    //       // 循环上传
    //       for (let i = 0; i < totalSlice; i++) {
    //         const start = i * sliceSize
    //         const end = Math.min(fileSize, start + sliceSize)
    //         const chunk = blob.slice(start, end)


    //         const formData = new FormData()
    //         formData.append("chunk", chunk)
    //         formData.append("signal", blob.uid)
    //         formData.append("name", fileName)
    //         formData.append("size", fileSize)
    //         formData.append("chunks", totalSlice)
    //         formData.append("chunkIndex", i + 1)
    //         formData.append("test_paper_id", this.$route.query.id)


    //         // 发送分片到服务器
    //         await this.$http({

    //           url: 'api/v1/test/upload_compress_sheet',
    //           method: 'post',
    //           data: formData,
    //           onUploadProgress: debounce(progressEvent => {

    //             // 更新已上传的字节数和上传进度
    //             uploadedBytes += progressEvent.loaded;
    //             uploadedBytesTotal += progressEvent.total
    //             this.uploadProgressDate = Math.min(parseFloat((uploadedBytes / uploadedBytesTotal * 100).toFixed(2)), 100);


    //           }, 100),


    //           // responseType: 'blob',
    //           headers: {
    //             'Content-Type': 'multipart/form-data'
    //           }
    //         }).then(res => {
    //           alert(2)
    //           console.log('res', res)

    //           if (res.data.is_over) {

    //             this.$notify({
    //               title: '提示',
    //               message: '上传成功!',
    //               type: 'success'
    //             });
    //             success = true
    //             this.uploadProgressDate = 100

    //           }
    //         })
    //         if (success) {
    //           break // 跳出循环
    //         }
    //       }
    //       this.refresh()
    //     } else {

    //       this.$message({
    //         message: '文件大小超出1G',
    //         type: 'error'
    //       })
    //     }
    //   } catch (error) {

    //   }
    // },

    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    getValue (prop, value) {
      if (prop == 'status') {
        return value ? '审核成功' : '未审核'
      }
    },
    getText (prop, value, row) {
      if (prop == 'd') {
        return row.status ? '详情' : '审核'
      }
    },
    async pollDetBatchTaskid () {
      console.log('ss', this.$route.path == '/makeSheet/sheetList')

      try {

        const statusResult = await detBatchTaskid({ edu_paper_id: Number(this.$route.query.paper_id || window.localStorage.getItem('paper_test_id')) })

        // 判断任务状态成功
        if (statusResult.data.taskid.length > 9 && statusResult.data.taskid != 'WAITING') {
          this.successFlag = false

          this.getList(this.page, this.size)
          this.startPoll(statusResult.data.taskid)

        } else if (statusResult.data.taskid == 'WAITING') {
          // if (this.successFlag) {
          // console.log('gasgasg', this.successFlag)
          this.$refs.dialogRef.btndisable = true
          this.uploading = true
          this.loadingText = "正在上传中..."

          // }

          setTimeout(() => {
            this.pollDetBatchTaskid();
          }, 3000); // 设置轮询的间隔时间，这里是3秒
        }
        else if (statusResult.data.taskid == '' && this.$route.path == '/makeSheet/sheetList') {
          // if (this.loadingText == "正在上传中...") {
          //   this.refresh()
          // }
          this.uploading = false
          this.getList(this.page, this.size)
          // this.$message.info("taskid为空")
          console.log("taskid为空")
        }
      } catch (error) {
        console.error('Error polling task status:', error);
        // 处理错误情况，例如中断轮询或显示错误信息
      }
    },
    async startPoll (resaskid) {

      console.log('resid', resaskid)
      let taskid = resaskid
      let polling = true; // 标志位，判断是否继续轮询
      if (!taskid) {
        this.uploading = false
      }

      const pollTaskStatus = async () => {
        if (polling && taskid) { // 添加taskid不为空的条件判断
          this.uploading = true
          this.loadingText = "正在识别中..."
          this.$refs.dialogRef.btndisable = true



          await taskStatus({ taskid: taskid, spec: 0 })
            .then(statusResult => {
              console.log('statusResult', statusResult);
              // 处理statusResult的数据

              // 判断任务状态是否完成，如果未完成，继续轮询
              if (statusResult.data.status == 'SUCCESS') {

                this.getList(this.page, this.size)
                this.$message.success("识别完成")
                this.uploading = false
                this.$refs.dialogRef.btndisable = false


                polling = false
              } else {
                setTimeout(pollTaskStatus, 3000); // 设置轮询的间隔时间，这里是3秒

              }
            })
            .catch(error => {
              this.uploading = false
              console.error('Error polling task status:', error);
              // 处理错误情况，例如中断轮询或显示错误信息
            });
        }
      };

      pollTaskStatus(); // 开始轮询调用taskStatus获取状态


    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    async initData (page, limit) {
      //   if(window.localStorage.getItem("sheet_paper_id")){
      //   this.$route.query.id=window.localStorage.getItem("sheet_paper_id")
      //   window.localStorage.removeItem("sheet_paper_id")
      // }
      let params = {
        page,
        limit,
        self: 1,
        edu_paper_id: Number(this.$route.query.paper_id) || window.localStorage.getItem("edu_paper_id"),
      }
      if (this.readValue) {
        params.identify_status = Number(this.readValue)
      }
      if (this.checkValue) {
        params.status = Number(this.checkValue)
      }
      const { data } = await getSheetList(params)

      this.tableData = data.list
      // this.tableData[0] = this.tableData[1]
      this.totalElements = data.count

      this.pollDetBatchTaskid()

    },
    async getList (page, limit) {
      let params = {
        page, limit,
        self: 1,
        edu_paper_id: Number(this.$route.query.paper_id) || window.localStorage.getItem("edu_paper_id"),
      }
      if (this.readValue) {
        params.identify_status = Number(this.readValue)
      }
      if (this.checkValue) {
        params.status = Number(this.checkValue)
      }
      const { data } = await getSheetList(params)

      this.tableData = data.list
      // this.tableData[0] = this.tableData[1]
      this.totalElements = data.count
    },
    toInfo (row) {
      console.log('row', row)
      let cardUrlObj = {
        url: row.url,
        url2: row.url2
      }
      // window.localStorage.setItem("sheet_paper_id", this.$route.query.id)
      window.localStorage.setItem("cardUrlObj", JSON.stringify(cardUrlObj))
      window.localStorage.setItem("edu_paper_id", this.$route.query.paper_id || window.localStorage.getItem('edu_paper_id'))
      this.$router.push('/makeSheet/sheetList/reviewSheet?sheet_id=' + row.edu_paper_answer_sheet_id + "&paper_id=" + this.$route.query.paper_id + '&status=' + row.status)

    },

    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },


    //显示上传弹窗
    add () {
      this.$refs.dialogRef.dialogVisible = true
    },
    uploadProgress (progressEvent) {
      if (progressEvent.lengthComputable) {   //是否存在进度
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        this.startLoading = percentCompleted//标记进度条记时开始
        this.$refs.dialogRef.disabled = true
      }
    },
    //弹窗回调上传
    async uploadSubmit (type) {

      if (this.sitv) { clearInterval(this.sitv) }

      try {
        // loadings.close()

        let count1 = 0, count2 = 0
        let randomProgress = Math.random() + ''
        this.sitv = setInterval(async () => {
          if (this.startLoading > 1 && count1 == count2) {
            count1 += 1
            let { data } = await uploadSheetSchedule({
              "progress": randomProgress,
              "test_paper_id": Number(this.$route.query.paper_id)
            })
            count2 += 1

            // console.log(data)
            this.$refs.dialogRef.percentage = Math.round(data.schedule)
          }
          // else {
          //   count1 -= 1
          // }
        }, 1500);
        // this.$refs.dialogRef.disabled = true
        const data = await uploadSheetFile("filenames", { "edu_paper_id": this.$route.query.paper_id, "type": type, 'progress': randomProgress }, 20, "image/*", this.uploadProgress)
        clearInterval(this.sitv)
        this.$refs.dialogRef.disabled = false
        this.startLoading = 0
        console.log("完成")
        // await this.apiRunning(type)
        // this.isloading=false
        // return
        this.$refs.dialogRef.percentage = 100
        this.$refs.dialogRef.status1 = 'success'
        // this.$refs.dialogRef.showProgress = false
        this.$refs.dialogRef.handleClose()
        this.$message.success("上传成功")
        this.uploading = true
        this.loadingText = "正在上传中..."
        setTimeout(() => {
          this.pollDetBatchTaskid()
        }, 3000)

      } catch (err) {
        console.log(err, err)
        clearInterval(this.sitv)
        this.startLoading = 0
        loadings.close()
        this.$refs.dialogRef.percentage = 100
        this.$refs.dialogRef.status1 = 'exception'
        this.$message.error("请重新上传")
        this.$refs.dialogRef.disabled = false
      }
    },

  },




}
</script>

<style lang="scss" scoped>
::v-deep .text_btn_primary {
  padding: 0px;
}
::v-deep .el-button + .el-button {
  margin-left: 16px;
}
::v-deep .el-loading-spinner .circular {
  height: 20px;
  width: 20px;
  font-size: 14px;
  margin-right: 8px;
}
::v-deep .el-loading-spinner {
  display: flex;
  margin-top: 3px;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .el-loading-spinner .el-loading-text {
  font-size: 14px;
}

.card {
  // width: 100%;
  background: white;
  // box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  margin-bottom: 24px;
  // padding: 24px;
  .ypg {
    font-size: 12px;
    padding: 2px 8px;
    line-height: 18px;
    display: inline-block;
    color: #66bb6a;
    height: 24px;
    // width: 88px;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    border: 1px solid #66bb6a;
  }
  .unload {
    // width: 88px;
    color: #ef5350;
    display: inline-block;
    padding: 2px 8px;
    height: 24px;
    font-size: 12px;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    line-height: 18px;
    border: 1px solid #ef5350;
  }
  .btn {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  span {
    font-size: 14px;

    font-weight: 400;
    color: #333333;
  }
  .title {
    width: 100%;
    height: 50px;
    // background: #eff8ff;
    font-size: 16px;
    font-weight: bold;
    // line-height: 50px;
    // text-indent: 10px;
  }
}
</style>
